import "./Timeline.scss";

export default function Timeline(){
	return (
		<div className="timeline">
			<Row
				year={2012}
				location={"Elon University"}
				accolades={"BA Philosophy, BS Physics"}
			/>
			<Row
				year={2016}
				location={"Western University"}
				accolades={"MA Philosophy"}
			/>
			<Row
				year={2017}
				location={"University of California, Irvine"}
				accolades={"PhD Philosophy"}
			/>
			<Row
				year={2023}
				location={"Black Hole Initiative, Harvard University"}
				accolades={"Postdoctoral Fellow"}
			/>
		</div>
	);
}

function Row({
	year, location, accolades
} : {
	year: number,
	location: string,
	accolades: string
}){
	return (
		<>
			<div>{year}</div>
			<div>
				<div className="bar"></div>
				<div className="dot"></div>
			</div>
			<div></div>
			<div></div>
			<div>
				<div className="bar"></div>
			</div>
			<div className="time-period">
				<div>{location}</div>
				<div>{accolades}</div>
			</div>
		</>
	);
}